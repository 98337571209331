
import "./modal-v1"
import "./checkout-order-details-content-v1"
import { CheckoutExistingOrders } from "@yggdrasil/Checkout/V2/Services/checkout-existing-orders"
import { addressToString } from '@yggdrasil/Checkout/V2/Utils/checkout-address'
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutOrderDetailsV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}




  mount() {
    this.getCloseButton().addEventListener('click', (evt) => {
      evt.preventDefault();
      Checkout.store.selectedOrderDetailId.set(null)
    })
    this.getModal().onClose = () => {
      Checkout.store.selectedOrderDetailId.set(null)
    }
    Checkout.store.selectedOrderDetailId.listen((selectedOrderDetailId) => {
      if (selectedOrderDetailId) {
        this.showModal()
        this.getContent().order_details = CheckoutExistingOrders.orderDetailsByOrderId[selectedOrderDetailId]
        const orderDetails = this.getContent().order_details
        this.formatOrderDetails(orderDetails)
        this.getContent().render()
      } else {
        this.hideModal()
      }
    })
  }

  formatOrderDetails(orderDetails) {
    orderDetails.created_at = new Date(orderDetails.created_at_epoch * 1000).toLocaleString(navigator.language)
    if(orderDetails.shipping_address) {
      orderDetails.shipping_address = addressToString(orderDetails.shipping_address)
    }
    if (orderDetails.billing_address) {
      orderDetails.billing_address = addressToString(orderDetails.billing_address)
    }
    if (orderDetails.line_items.length) {
      this.findAndAddProductsImage(orderDetails.line_items)
    }
  }
  getContent() {
    return this.getComponent("CheckoutOrderDetailsContent/V1")
  }
  getModal() {
    return this.getComponent("Modal/V1")
  }
  getCloseButton() {
    return this.getModal().element.querySelector('.elCheckoutModalFormClose')
  }
  showModal() {
    this.getModal().element.style.display = 'flex'
  }
  hideModal() {
    this.getModal().element.style.display = 'none'
  }
  findAndAddProductsImage(lineItems) {
    lineItems.forEach((lineItem) => {
      lineItem.image = Checkout.variantsById[lineItem.variant_id].image ?? Checkout.productsById[lineItem.product_id].image
    })
  }



}

registerComponent('CheckoutOrderDetails/V1', CheckoutOrderDetailsV1)
window["CheckoutOrderDetailsV1"] = CheckoutOrderDetailsV1

